import React from "react";
import { Root } from "./style";

import Web3 from "./Web3";

import { inWindow } from "utils";

const Claim = (props) => {
  return <Root>{inWindow() ? <Web3 {...props} /> : <div />}</Root>;
};

export default Claim;
