import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  display: flex;
  letter-spacing: 0.5px;

  .wallet {
    margin-left: auto;
    display: inline-block;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: ${vars.colors.marineBlue};
  }

  ${vars.desktop.mediaQuery} {
    .disconnect,
    .wallet {
      font-size: 0.775rem;
    }

    .disconnect {
      padding: 20px 60px;
    }

    .wallet {
      width: 120px;
      margin-right: 20px;
      padding: 20px 30px;
      border-radius: 9px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .disconnect,
    .wallet {
      padding: 4px 9px;
      font-size: 0.585rem;
      border-radius: 5px;
    }

    .wallet {
      width: 60px;
      margin-right: 10px;
    }
  }
`;

export { Root };
