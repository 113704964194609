import styled from 'styled-components';

import vars from 'variables';

import down from 'assets/FAQs/Questions/down.svg';
import up from 'assets/FAQs/Questions/up.svg';

const Root = styled.div`
  li {
    margin-bottom: 20px;
    padding: 27px 30px 25px;
    cursor: pointer;
    background: rgb(0, 10, 35);
    border: 2px solid rgb(0, 149, 213);
    border-radius: 10px;
  }

  strong {
    display: block;
    text-transform: uppercase;
    background: url(${down}) no-repeat center right;
  }

  p {
    margin-top: 10px;
    display: none;
  }

  li.active {
    cursor: default;
  }

  li.active strong {
    background-image: url(${up});
  }

  li.active p {
    display: block;
  }

  ${vars.desktop.mediaQuery} {
    & {
      margin-bottom: 115px;
    }

    p {
      color: ${vars.colors.grey};
      font-size: 0.875rem;
      line-height: 165%;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-bottom: 55px;
    }
  }
`;

export { Root };
