import React, { useEffect, useRef, useState } from 'react';
import { Root } from './style';

import Draggable from 'react-draggable';

import { formatNumber } from 'utils';
import { useWeb3 } from 'web3';

import { getCollectTokens } from 'web3';

const Slider = ({ state }) => {
  const { wallet } = useWeb3();

  const [collectableTokens, setCollectableTokens] = useState();

  useEffect(() => {
    const getPrices = async () => {
      const claimableBNB = wallet.stackingEnabled
        ? wallet.stackedBNB
        : wallet.claimableBNB;
      const tokenAmounts = await getCollectTokens(claimableBNB);
      setCollectableTokens(tokenAmounts);
    };
    getPrices();
  }, [wallet]);

  const initialX = () =>
    (document.body.offsetWidth > 480 ? 5.5 : 2.16) * state.percentageBNB.get();

  const calculateRewards = () => {
    const claimableBNB = wallet.stackingEnabled
      ? wallet.stackedBNB
      : wallet.claimableBNB;

    state.rewardBNB.set(
      Number(((claimableBNB * state.percentageBNB.get()) / 100).toFixed(5))
    );

    state.rewardTOKEN.set(
      (collectableTokens * state.percentageTOKEN.get()) / 100
    );
  };

  const slider = useRef();
  const knob = useRef();

  const onDrag = (e, data) => {
    try {
      const maxWidth = slider.current.offsetWidth - knob.current.offsetWidth;
      state.percentageBNB.set(Math.round((data.x / maxWidth) * 100));
      state.percentageTOKEN.set(100 - state.percentageBNB.get());
      sessionStorage.setItem('percentageBNB', state.percentageBNB.get());
      sessionStorage.setItem('percentageTOKEN', state.percentageTOKEN.get());
      calculateRewards();
    } catch (error) {}
  };

  useEffect(() => {
    const claimableBNB = wallet.stackingEnabled
      ? wallet.stackedBNB
      : wallet.claimableBNB;
    if (claimableBNB && collectableTokens) {
      calculateRewards();
    }
  }, [collectableTokens]);

  return (
    <Root>
      <div
        className={
          state.percentageTOKEN.get() === 100 ? 'tooltip' : 'tooltip hide'
        }
      >
        🔥 Reinvest 100% into $HODL and your next claim will be 1 day earlier 🔥
      </div>
      <div>
        <span>{state.percentageTOKEN.get()}%</span>
        <div className="amount">
          <strong>
            {state.rewardTOKEN.get() > 0 ? '≈ ' : ''}
            {formatNumber(state.rewardTOKEN.get())}
          </strong>
          <label className="hodl">HODL</label>
        </div>
        <div className="slider" ref={slider}>
          <Draggable
            axis="x"
            handle=".knob"
            bounds="parent"
            defaultPosition={{ x: initialX(), y: 0 }}
            onDrag={onDrag}
          >
            <div className="knob" ref={knob} />
          </Draggable>
        </div>
        <span>{state.percentageBNB.get()}%</span>
        <div className="amount">
          <strong>
            {state.rewardBNB.get() > 0 ? '≈ ' : ''}
            {state.rewardBNB.get()}
          </strong>
          <label className="bnb">BNB</label>
        </div>
      </div>
    </Root>
  );
};

export default Slider;
