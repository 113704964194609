import React from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';

const Questions = ({ questions }) => {
  const state = useState(-1);

  return (
    <Root className="body-width">
      <ul>
      {questions.map((question, index) =>
        <li key={index}
            className={state.get() === question.id ? 'active' : ''}
            onClick={() => state.set(question.id)}>
          <strong>{question.question}</strong>
          <p>{question.answer}</p>
        </li>
      )}
      </ul>
    </Root>
  );
};

export default Questions;
