import React from "react";
import { Root } from "./style";

import { formatNumber, useSiteData } from "utils";
import { useWeb3 } from "web3";

import PageHeader from "components/Common/PageHeader";
import SiteData from "components/Common/SiteData";
import Questions from "./Questions";

import coin from "assets/Claim/coin.svg";
import vars from "variables";

const Disconnected = () => {
  const {
    claim: { claimedText, claimFaqs },
  } = useSiteData().pages;

  const { bsc } = useWeb3();

  const questions = [];
  let currentQuestion;

  claimFaqs.split(/\n/).forEach((line) => {
    if (line.match(/^\s*---\s*$/)) {
      currentQuestion = null;
    } else if (!currentQuestion) {
      currentQuestion = { id: questions.length, question: line, answer: "" };
      questions.push(currentQuestion);
    } else {
      currentQuestion.answer += (currentQuestion.answer ? "\n" : "") + line;
    }
  });

  return (
    <Root>
      <div className="header">
        <PageHeader page="claim" />
        <div className="claimed">
          <p>{claimedText}</p>
          <div>
            <h3>{formatNumber(bsc.totalClaimedBNB, 0)} BNB</h3>
            <strong>
              *Worth ${formatNumber(bsc.totalClaimedBNB * bsc.bnbUsdPrice, 0)}
            </strong>
            <img src={coin} />
          </div>
        </div>
      </div>
      <div className="body-width">
        <SiteData path="claim.callToAction" />
        <a
          className="button blue logo"
          href={vars.links.buy}
          target="_blank"
          rel="noreferrer"
        >
          Buy $HODL now
        </a>
        <div className="hr" />
        <h3>Claim FAQs</h3>
        <Questions questions={questions} />
      </div>
    </Root>
  );
};

export default Disconnected;
