import React from 'react';
import { Root } from './style';

import usePortal from 'react-useportal';

import { useWeb3 } from 'web3';

const Wallet = () => {
  const { Portal } = usePortal({
    bindTo: document && document.getElementById('menu-buttons')
  });

  const { wallet, disconnect } = useWeb3();

  return (
    <Portal>
      <Root>
        <strong className="wallet">
          { wallet.account }
        </strong>
        <a className="button disconnect blue" onClick={disconnect}>
          Disconnect
        </a>
      </Root>
    </Portal>
  );
};

export default Wallet;
