import styled from "styled-components";

import vars from "variables";

import coins from "assets/Claim/coin.svg";
import coins1 from "assets/Claim/coins1.svg";
import coins2 from "assets/Claim/coins2.svg";
import coins3 from "assets/Claim/coins3.svg";
import share from "assets/Claim/Page/Claimed/share.svg";

const Root = styled.div`
  .header {
    position: relative;
    background-color: rgb(0, 3, 22);
    background-repeat: no-repeat;
  }

  h4 span {
    font-weight: 500;
    letter-spacing: 2px;
  }

  .raw-html.claimed {
    color: ${vars.colors.grey};
  }

  .raw-html.claimed span {
    color: ${vars.colors.white};
  }

  a.back {
    margin-right: 20px;
  }

  a.share {
    background-image: url(${share});
    background-repeat: no-repeat;
  }

  .status label {
    color: ${vars.colors.grey};
  }

  .status p {
    color: white;
  }

  ${vars.desktop.mediaQuery} {
    .header {
      margin-top: -120px;
      padding-top: 100px;
      padding-bottom: 100px;
      background-image: url(${coins1}), url(${coins2}), url(${coins3}),
        url(${coins});
      background-position: top 300px left, top 200px right, top 850px right,
        top 950px left 275px;
      background-size: auto, auto, auto, auto;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }

    .header img {
      width: 400px;
    }

    h3 {
      margin-bottom: 20px;
      font-size: 2.4rem;
      font-weight: 900;
      letter-spacing: 0.5px;
    }

    h4 {
      margin-top: 70px;
    }

    .raw-html.claimed {
      padding: 20px 0 12px;
    }

    a.back {
      padding-left: 50px;
      padding-right: 50px;
    }

    a.share {
      margin-top: 40px;
      padding-left: 50px;
      padding-right: 70px;
      font-size: 0.85rem;
      background-position: center right 45px;
    }

    .status {
      padding-top: 70px;
    }

    .status p {
      margin-bottom: 10px;
      font-size: 1.35rem;
      letter-spacing: 0.5px;
    }

    .join-the-communities {
      margin-top: 115px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .header {
      margin-top: -90px;
      padding-top: 100px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
    }

    .header img {
      width: calc(100vw - 140px);
    }

    h1 {
      font-size: 2.15rem;
    }

    h3 {
      margin-top: -10px;
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    h4 {
      margin-top: 35px;
    }

    .raw-html.claimed {
      padding: 8px 0 12px;
    }

    a.back {
      padding-left: 40px;
      padding-right: 40px;
    }

    a.share {
      margin-top: 30px;
      margin-bottom: 40px;
      padding-left: 40px;
      padding-right: 55px;
      font-size: 0.6rem;
      background-position: center right 38px;
      background-size: 12px;
    }

    .status {
      padding-bottom: 45px;
    }

    .join-the-communities {
      margin: 20px 0 40px;
    }
  }
`;

export { Root };
